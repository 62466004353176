import React, { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import DataTime from 'react-datetime';
import { format } from 'date-fns';
import { db } from '../../firebase/firebaseConfigs';
import OutsideClickHandler from 'react-outside-click-handler';

import Modal from '../common/Modal/Modal';

import { ReactComponent as Close } from '../Icons/close.svg';
import { ReactComponent as CalendarIcon } from '../Icons/calendar.svg';
import { ReactComponent as Right } from '../../Components/Icons/right.svg';

import './ContactUsModal.scss';
import { FirestoreCollection } from '@react-firebase/firestore';

import ReactDOMServer from 'react-dom/server';

interface ContactUsModalProps {
	contactModal: boolean;
	setContactModal: Dispatch<SetStateAction<boolean>>;
}

interface FormData {
	name: string;
	lastName: string;
	email: string;
	phone: string;
	data: string;
	text: string;
	location: string;
	emailTo: string;
}

export default function ContactUsModal({ contactModal, setContactModal }: ContactUsModalProps) {
	const { register, handleSubmit, errors } = useForm();
	const [formData, setFormData] = useState<FormData>({
		name: '',
		lastName: '',
		email: '',
		phone: '',
		data: '',
		text: '',
		location: '',
		emailTo: '',
	});
	const [emailTemplate, setEmailTemplate] = useState<string>('');
	const [sendStatus, setSendStatus] = useState<number | undefined>();
	const [showCalendar, setShowCalendar] = useState(false);
	const [disableSendButton, setDisableSendButton] = useState(false);
	const [openedLocationSelector, setOpenedLocationSelector] = useState(false);
	const [replyEmail, setReplyEmail] = useState('');

	const updateInput = (e: any) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
		setSendStatus(undefined);
	};
	const onSubmit = () => {
		sendEmail();

		setSendStatus(undefined);
	};

	const sendEmail = () => {
		setDisableSendButton(true);
		if (formData.location !== '') {
			db.collection('workspaceForms')
				.add({
					name: formData.name,
					lastName: formData.lastName,
					email: formData.email,
					phone: formData.phone,
					text: formData.text,
					dataOnCall: formData.data,
					location: formData.location,
					createDate: new Date(),
				})
				.then(() => setSendStatus(200))
				.catch(() => setSendStatus(400));
			db.collection('buildEmail').add({
				to: [formData.emailTo],
				from: 'info@myglobalworkspace.com',
				message: {
					subject: 'New request has been added by website - My Global Workspace',
					html: emailTemplate,
				},
			});
			db.collection('buildEmail').add({
				to: [replyEmail],
				from: 'info@myglobalworkspace.com',
				message: {
					subject: 'New request has been added by website',
					html: emailTemplate,
				},
			});
		}
		setFormData({
			name: '',
			lastName: '',
			email: '',
			phone: '',
			data: '',
			text: '',
			location: '',
			emailTo: '',
		});

		setDisableSendButton(false);
	};

	return (
		<Modal isOpen={contactModal} onRequestClose={() => setContactModal(false)} className="contactUsModalOverlay">
			<FirestoreCollection path="/myGlobalWorkspace/">
				{(data) => {
					if (data.isLoading) {
						return;
					}
					console.log(data.value[0].replyEmail);
					setReplyEmail(data.value[0].replyEmail);

					const template = ReactDOMServer.renderToString(
						<html>
							<head>
								<meta charSet="utf-8" />
							</head>
							<body>
								<div
									style={{
										width: '600px',
										margin: '40px auto 0',
										padding: '0 20px',
									}}
								>
									<img
										alt=""
										style={{ width: '100%' }}
										src="https://firebasestorage.googleapis.com/v0/b/admin-gisua.appspot.com/o/headRes.png?alt=media&token=a0d7cf32-7856-468f-9784-4adfa65247eb"
									/>
									<div
										style={{
											textAlign: 'center',
											padding: '15px 20px 0',
											fontSize: '16px',
											fontWeight: 'bold',
											maxWidth: '750px',
											margin: '0 auto',
											textTransform: 'uppercase',
											color: '#24292e',
										}}
									>
										Do You Have Any Questions?
									</div>
									<div style={{ width: '100%' }}>
										<div
											style={{
												maxWidth: '1150px',
												margin: '0 auto',
												padding: '20px 0',
											}}
										>
											<div
												style={{
													color: '#24292e',
													fontWeight: 'bold',
													padding: '17px 0',
													minWidth: '200px',
													fontSize: '14px',
													whiteSpace: 'nowrap',
													display: 'inline-block',
												}}
											>
												Name:
											</div>
											<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
												{formData.name}
											</div>
										</div>
										<div
											style={{
												maxWidth: '1150px',
												margin: '0 auto',
												padding: '20px 0',
											}}
										>
											<div
												style={{
													color: '#24292e',
													fontWeight: 'bold',
													padding: '17px 0',
													minWidth: '200px',
													fontSize: '14px',
													whiteSpace: 'nowrap',
													display: 'inline-block',
												}}
											>
												Location:
											</div>
											<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
												{formData.location}
											</div>
										</div>
										{formData.lastName && (
											<div
												style={{
													maxWidth: '1150px',
													margin: '0 auto',
													padding: '20px 0',
												}}
											>
												<div
													style={{
														color: '#24292e',
														fontWeight: 'bold',
														padding: '17px 0',
														minWidth: '200px',
														fontSize: '14px',
														whiteSpace: 'nowrap',
														display: 'inline-block',
													}}
												>
													Last Name:
												</div>
												<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
													{formData.lastName}
												</div>
											</div>
										)}
										{formData.email && (
											<div
												style={{
													maxWidth: '1150px',
													margin: '0 auto',
													padding: '20px 0',
												}}
											>
												<div
													style={{
														color: '#24292e',
														fontWeight: 'bold',
														padding: '17px 0',
														minWidth: '200px',
														fontSize: '14px',
														whiteSpace: 'nowrap',
														display: 'inline-block',
													}}
												>
													Email:
												</div>
												<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
													{formData.email}
												</div>
											</div>
										)}
										{formData.phone && (
											<div
												style={{
													maxWidth: '1150px',
													margin: '0 auto',
													padding: '20px 0',
												}}
											>
												<div
													style={{
														color: '#24292e',
														fontWeight: 'bold',
														padding: '17px 0',
														minWidth: '200px',
														fontSize: '14px',
														whiteSpace: 'nowrap',
														display: 'inline-block',
													}}
												>
													Phone:
												</div>
												<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
													{formData.phone}
												</div>
											</div>
										)}
										{formData.text && (
											<div
												style={{
													maxWidth: '1150px',
													margin: '0 auto',
													padding: '20px 0',
												}}
											>
												<div
													style={{
														color: '#24292e',
														fontWeight: 'bold',
														padding: '17px 0',
														minWidth: '200px',
														fontSize: '14px',
														whiteSpace: 'nowrap',
														display: 'inline-block',
													}}
												>
													Message:
												</div>
												<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
													{formData.text}
												</div>
											</div>
										)}
									</div>
								</div>
							</body>
						</html>
					);

					return (
						<div className="contactUsModal">
							<div className="contactUsTitle">Do You Have Any Questions?</div>
							<form onSubmit={handleSubmit(onSubmit)}>
								<input
									name="name"
									type="text"
									className={errors.name ? 'contactUsInputError' : 'contactUsInput'}
									placeholder="Name"
									ref={register({
										required: 'Required Name',
										minLength: 2,
									})}
									onChange={updateInput}
									value={formData.name || ''}
								/>
								<input
									type="text"
									name="lastName"
									className="contactUsInput"
									placeholder="Last name"
									onChange={updateInput}
									value={formData.lastName || ''}
								/>
								<input
									name="email"
									type="text"
									className={errors.email ? 'contactUsInputError' : 'contactUsInput'}
									placeholder="Email address"
									ref={register({
										required: 'Required Email',
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: 'invalid email address',
										},
									})}
									onChange={updateInput}
									value={formData.email || ''}
								/>
								<input
									type="text"
									name="phone"
									className={errors.phone ? 'contactUsInputError' : 'contactUsInput'}
									placeholder="Phone number"
									ref={register({
										required: 'Required Phone',
										pattern: {
											value: /^\+?[0-9- ]{9,20}$/i,
											message: 'invalid phone number',
										},
									})}
									onChange={updateInput}
									value={formData.phone || ''}
								/>
								<div className="inputsWrapper">
									<div
										className="contactUsInputCalendarWrapper"
										onClick={() => {
											setShowCalendar(true);
											setOpenedLocationSelector(false);
										}}
									>
										<input
											type="text"
											name="data"
											disabled
											className="contactUsCalendarInput"
											placeholder="Schedule a call"
											onChange={updateInput}
											value={formData.data || ''}
										/>
										<CalendarIcon />
										{showCalendar && (
											<OutsideClickHandler onOutsideClick={() => setShowCalendar(false)}>
												<DataTime
													className="contactUsCalendar"
													input={false}
													onChange={(e: any) => setFormData({ ...formData, data: format(e._d, 'dd-MM-yyyy hh:mm aaa') })}
												/>
											</OutsideClickHandler>
										)}
									</div>
									<div className={openedLocationSelector ? 'openedLocationSelectorWrapper' : 'serviceSelectorWrapper'}>
										<div className="locationsInputWrapper" onClick={() => setOpenedLocationSelector(!openedLocationSelector)}>
											<input
												className={errors.location && formData.location === '' ? 'serviceSelectorErr' : 'serviceSelector'}
												type="text"
												name="location"
												ref={register({
													required: 'Required Location',
												})}
												placeholder="Location"
												disabled
												onChange={updateInput}
												value={formData.location || ''}
											/>
											<div className="locationsInputButton">
												<Right className={openedLocationSelector ? 'openService' : 'closedService'} />
											</div>
										</div>

										{openedLocationSelector && (
											<div className="serviceSelectorList">
												{data.value[0].locations.map((loc: { location: string; email: string }, index: number) => (
													<div
														key={index}
														className="service"
														onClick={() => {
															setFormData({ ...formData, location: loc.location, emailTo: loc.email });
															setOpenedLocationSelector(false);
														}}
													>
														{loc.location}
													</div>
												))}
											</div>
										)}
									</div>
								</div>
								<div className="fieldTitle">Details</div>
								<textarea
									name="text"
									className="contactUsTextArea"
									rows={4}
									placeholder="Message..."
									onChange={updateInput}
									value={formData.text || ''}
								/>
								<div className="contactUsEmailStatus">
									{sendStatus === 200 && <div className="contactUsSuccess">Successfully</div>}
									{sendStatus === 400 && <div className="contactUsError">Something went wrong</div>}
									{errors.email && <div className="contactUsError">{errors.email.message}</div>}
								</div>
								<button
									type="submit"
									onClick={() => setEmailTemplate(template)}
									disabled={disableSendButton}
									className="contactUsButton"
								>
									CALL ME BACK
								</button>
							</form>
							<button onClick={() => setContactModal(false)} className="closeContactUsModal">
								close <Close />
							</button>
						</div>
					);
				}}
			</FirestoreCollection>
		</Modal>
	);
}
