import React, { Dispatch, SetStateAction } from 'react';
import { FirestoreCollection } from '@react-firebase/firestore';

import Social from '../../Social/Social';
import Covid from '../../common/Covid/Covid';

import Modal from '../../common/Modal/Modal';

import { ReactComponent as Vector } from '../../Icons/vector.svg';
import { ReactComponent as CloseBtn } from '../../Icons/closeBtn.svg';
import { ReactComponent as Shape } from '../../Icons/shape.svg';

import './MenuModal.scss';

interface MenuProps {
	menuOpened: boolean;
	covidOpened: boolean;
	setMenuOpened: Dispatch<SetStateAction<boolean>>;
	setCovidOpened: Dispatch<SetStateAction<boolean>>;
	language: any;
	setLanguage: any;
	langDropdownOpened: boolean;
	setLangDropdownOpened: Dispatch<SetStateAction<boolean>>;
}

export default function Menu({
	menuOpened,
	setMenuOpened,
	setCovidOpened,
	covidOpened,
	language,
	setLanguage,
	langDropdownOpened,
	setLangDropdownOpened,
}: MenuProps) {
	return (
		<Modal isOpen={menuOpened} onRequestClose={() => setMenuOpened(false)} className="menuModalOverlay">
			<div className="menuModalContent">
				<div
					onClick={() => {
						setMenuOpened(false);
					}}
					className="menuCloseWrapper"
				>
					<CloseBtn />
				</div>
				<div className="menuLinksWrapper">
					<div className="menuTitle">Menu</div>
					<div className="menuLinks">
						<div className="menuLinksContainer"></div>
					</div>
				</div>
				<div className="menuBackgraundWrapper">
					<Vector className="menuModalVector" />
				</div>
				<div className="menuModalSocialWrapper">
					<div className="languageContainerMob">
						<div
							className={langDropdownOpened ? 'languageSelectorOpened' : 'languageSelector'}
							onClick={() => setLangDropdownOpened(!langDropdownOpened)}
						>
							{language === 'en' ? 'EN' : 'UA'}
							<Shape />
						</div>
						{langDropdownOpened && (
							<div className="languageDropdown">
								<div
									className="languageDropdownValue"
									onClick={() => {
										setLanguage(language === 'en' ? 'ua' : 'en');
										window.location.href = '/';
									}}
								>
									{language === 'en' ? 'UA' : 'EN'}
								</div>
							</div>
						)}
					</div>
					<FirestoreCollection path="/infoModal/">
						{(infoData) => {
							if (infoData.isLoading) {
								return;
							}
							return infoData.value[0].shown && infoData.value[0].image.original.src ? (
								<div className="menuModalCovidWrapper">
									<div onClick={() => setCovidOpened(true)} className="menuModalCovidButton">
										<img src={infoData.value[0].image.sm.src} alt="" />
									</div>
									{covidOpened && <Covid setCovidOpened={setCovidOpened} {...infoData.value[0]} />}
								</div>
							) : null;
						}}
					</FirestoreCollection>
					<div className="mobileSocialWrapper">
						<Social />
					</div>
				</div>
			</div>
		</Modal>
	);
}
