import React from 'react';
import { Helmet } from 'react-helmet';

export default function HideBodyScroll() {
	return (
		<Helmet
			style={[
				{
					cssText: `
					body {
						overflow: hidden;
					}
				`,
				},
			]}
		/>
	);
}
