import React from 'react';
import ReactModal from 'react-modal';

import HideBodyScroll from '../HideBodyScroll';

import './Modal.scss';

interface ModalPropTypes extends ReactModal.Props {
	children: React.ReactNode;
}

export default function Modal({ children, overlayClassName, ...otherProps }: ModalPropTypes) {
	return (
		<ReactModal overlayClassName={overlayClassName} {...otherProps}>
			<HideBodyScroll />
			{children}
		</ReactModal>
	);
}

ReactModal.setAppElement('#root');
