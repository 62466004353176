import React from 'react';
import { FirestoreCollection } from '@react-firebase/firestore';

import { ReactComponent as Facebook } from '../Icons/facebook.svg';
import { ReactComponent as Instagram } from '../Icons/instagram.svg';

export default function Social() {
	return (
		<FirestoreCollection path="/social/">
			{(data) => {
				if (data.isLoading) {
					return;
				}
				const social = data.value[0];
				return (
					<>
						{social.facebook && (
							<a href={social.workspaceFacebook} target="_blank" rel="noopener noreferrer">
								<Facebook />
							</a>
						)}
						{social.instagram && (
							<a href={social.workspaceInstagram} target="_blank" rel="noopener noreferrer">
								<Instagram />
							</a>
						)}
					</>
				);
			}}
		</FirestoreCollection>
	);
}
