import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import Social from '../Social/Social';

import { ReactComponent as Phone } from '../Icons/phone.svg';
import { ReactComponent as Email } from '../Icons/email.svg';
import { ReactComponent as Location } from '../Icons/location.svg';
import { ReactComponent as GooglePlay } from '../Icons/footerGooglePlay.svg';
// import { ReactComponent as AppStore } from '../Icons/footerAppStore.svg';
import { ReactComponent as LogoBlack } from '../Icons/logoBlack.svg';

import './Footer.scss';
import { FirestoreCollection } from '@react-firebase/firestore';

export default function Footer() {
	return (
		<FirestoreCollection path="/myGlobalWorkspace/">
			{(data) => {
				if (data.isLoading) {
					return;
				}

				const locations = data.value[0].locations;

				return (
					<div className="footer">
						<NavLink to="/">
							<LogoBlack className="footerLogoIcon" />
						</NavLink>
						<div className="footerLinks">
							<div className="footerPhoneWithSocial">
								<a href="tel:+38 (098) 111-84-35" className="footerPhone">
									<Phone />
									+38 (098) 111-84-35{' '}
								</a>
								<a href="tel:+62 (8214) 752-18-02" className="footerPhone">
									<Phone />
									+62 (8214) 753-18-02{' '}
								</a>
								<div className="footerSocialIconsWrapper">
									<Social />
								</div>
							</div>
							<div>
								<a href="mailto:info@myglobalworkspace.com" className="footerEmail">
									<Email />
									info@myglobalworkspace.com
								</a>
							</div>
							<div>
								<div className="footerLocations">
									<Location />
									<div>
										{locations.map((loc: { link: string; location: string }, index: number) => (
											<a href={loc.link} rel="noopener noreferrer" target="_blank" key={index}>
												{loc.location}
											</a>
										))}
									</div>
								</div>
							</div>
							<div className="mobileFooterSocialIconsWrapper">
								<Social />
							</div>
							<div>
								<div className="footerAppButtons">
									Get the App
									<div className="footerAppButtonsWrapper">
										<a
											href="https://play.google.com/store/apps/details?id=com.myglobalworkspace.mgws"
											rel="noopener noreferrer"
											target="_blank"
										>
											<GooglePlay />
										</a>
										{/* <a href="/">
								<AppStore />
							</a> */}
									</div>
								</div>
							</div>
						</div>

						<div className="footerEndCopyrightWrapper">
							<div className="footerEndCopyright">
								<div className="footerCopyright">
									Copyright @ 2022 GIS. <span>All Rights Reserved.</span>
								</div>
								<div>
									<Link to="/terms" className="footerLink">
										Terms & Conditions
									</Link>
									<Link to="/privacy" className="footerLink">
										Privacy Policy
									</Link>
								</div>
							</div>
						</div>
					</div>
				);
			}}
		</FirestoreCollection>
	);
}
