import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FirestoreCollection } from '@react-firebase/firestore';
import { NavLink, useLocation } from 'react-router-dom';
import Media from 'react-media';

import MenuModal from './MenuModal/MenuModal';
import Covid from '../common/Covid/Covid';

import { ReactComponent as LogoWithText } from '../Icons/logoWithText.svg';
import { ReactComponent as LogoBlack } from '../Icons/logoBlack.svg';
import { ReactComponent as Menu } from '../Icons/menu.svg';
import { ReactComponent as MenuBlack } from '../Icons/menuBlack.svg';
import { ReactComponent as Shape } from '../Icons/shape.svg';

import './Header.scss';

interface HeaderProps {
	setCovidOpened: Dispatch<SetStateAction<boolean>>;
	covidOpened: boolean;
	language: any;
	setLanguage: any;
}

export default function Header({ setCovidOpened, covidOpened, language, setLanguage }: HeaderProps) {
	const [menuOpened, setMenuOpened] = useState(false);
	const [headerChanged, setHeaderChanged] = useState(false);
	const [langDropdownOpened, setLangDropdownOpened] = useState(false);
	const location = useLocation();

	const listenScrollEvent = () => {
		if (window.scrollY < 70) {
			return setHeaderChanged(false);
		} else if (window.scrollY > 140) {
			return setHeaderChanged(true);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', listenScrollEvent);

		return () => window.removeEventListener('scroll', listenScrollEvent);
	}, []);

	return (
		<>
			<div className={location.pathname === '/' ? 'homeHeader' : 'header'}>
				<div className={headerChanged ? 'stickyHeaderContent' : 'headerContent'}>
					<NavLink to="/" className="logoWrapper">
						{location.pathname === '/' && !headerChanged ? (
							<LogoWithText className="logoIcon" />
						) : (
							<LogoBlack className="logoIcon" />
						)}
					</NavLink>
					<Media query="(max-width: 930px)">
						{(matches) =>
							!matches ? (
								<div className="infoWithSocialWrapper">
									<div
										className={
											headerChanged
												? 'languageContainerSticky'
												: location.pathname === '/terms' || location.pathname === '/privacy'
												? 'languageContainerSticky'
												: 'languageContainer'
										}
									>
										<div
											className={langDropdownOpened ? 'languageSelectorOpened' : 'languageSelector'}
											onClick={() => setLangDropdownOpened(!langDropdownOpened)}
										>
											{language === 'en' ? 'EN' : 'UA'}
											<Shape />
										</div>
										{langDropdownOpened && (
											<div className="languageDropdown">
												<div
													className="languageDropdownValue"
													onClick={() => {
														setLanguage(language === 'en' ? 'ua' : 'en');
														setLangDropdownOpened(false);
														window.location.href = '/';
													}}
												>
													{language === 'en' ? 'UA' : 'EN'}
												</div>
											</div>
										)}
									</div>
									<FirestoreCollection path="/infoModal/">
										{(infoData) => {
											if (infoData.isLoading) {
												return;
											}
											return infoData.value[0].shown && infoData.value[0].image.original.src ? (
												<div className="headerCovidWrapper">
													<div onClick={() => setCovidOpened(true)} className="headerCovidButton">
														<img src={infoData.value[0].image.original.src} alt="" />
													</div>
													{covidOpened && <Covid setCovidOpened={setCovidOpened} {...infoData.value[0]} />}
												</div>
											) : null;
										}}
									</FirestoreCollection>
								</div>
							) : (
								<div className="menuHeaderWrapper">
									<FirestoreCollection path="/infoModal/">
										{(infoData) => {
											if (infoData.isLoading) {
												return;
											}
											return infoData.value[0].shown && infoData.value[0].image.original.src ? (
												<div className="headerCovidWrapper">
													<div onClick={() => setCovidOpened(true)} className="headerCovidButton">
														<img src={infoData.value[0].image.original.src} alt="" />
													</div>
													{covidOpened && <Covid setCovidOpened={setCovidOpened} {...infoData.value[0]} />}
												</div>
											) : null;
										}}
									</FirestoreCollection>
									<div onClick={() => setMenuOpened(true)} className="menuHeader">
										{location.pathname === '/' && !headerChanged ? <Menu /> : <MenuBlack />}
									</div>
									<MenuModal
										menuOpened={menuOpened}
										setMenuOpened={setMenuOpened}
										setCovidOpened={setCovidOpened}
										covidOpened={covidOpened}
										language={language}
										setLanguage={setLanguage}
										langDropdownOpened={langDropdownOpened}
										setLangDropdownOpened={setLangDropdownOpened}
									/>
								</div>
							)
						}
					</Media>
				</div>
			</div>
		</>
	);
}
