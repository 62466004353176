import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

// Initialize Firebase
export const config = {
	apiKey: 'AIzaSyAz4fOys92vhlnNAunZn1Tb6WPw2zd2wmM',
	authDomain: 'admin-myglobalworkspace.firebaseapp.com',
	databaseURL: 'https://admin-myglobalworkspace-default-rtdb.europe-west1.firebasedatabase.app',
	projectId: 'admin-myglobalworkspace',
	storageBucket: 'admin-myglobalworkspace.appspot.com',
	messagingSenderId: '191901590323',
};

firebase.initializeApp(config);

const db = firebase.firestore();

export { db };
