import React from 'react';
import { compact } from 'lodash/fp';
import loadable from '@loadable/component';
import queryString from 'query-string';

const pathsByMarket: Partial<Record<RouteKey, string>> = {
	home: '/',
	privacy: '/privacy',
	terms: '/terms',
	nestedterms: '/terms/:slug',

	notFound: '',
};

const routeComponents: Record<string, React.ComponentType<any>> = {
	home: loadable(() => import('./pages/Home/Home')),
	privacy: loadable(() => import('./pages/Privacy/Privacy')),
	terms: loadable(() => import('./pages/Terms/Terms')),
	nestedterms: loadable(() => import('./pages/Terms/NestedTerms')),

	notFound: loadable(() => import('./pages/NotFound/NotFound')),
};

type RouteKey = keyof typeof routeComponents;

export default function getPlainRoutes(
	routeOrder: ReadonlyArray<RouteKey> = ['home', 'privacy', 'terms', 'nestedterms', 'notFound']
) {
	return compact(
		routeOrder.map((key) => {
			const path = pathsByMarket[key];

			return path !== undefined
				? {
						path,
						component: routeComponents[key],
						exact: true,
				  }
				: undefined;
		})
	);
}

export function getPath(routeKey: RouteKey, params?: RouteParams, query?: object, state?: object) {
	const path = routeKey;
	if (path === undefined) return null;
	const interpolated = interpolateParams(path, params);
	const prefixed = `${interpolated}`;
	return { pathname: prefixed, search: query ? `?${queryString.stringify(query)}` : '', state };
}

type RouteParams = Record<string, string | number>;

export function interpolateParams(path: string, params?: RouteParams) {
	const parts = path.split('/');
	const replaced = parts.map((part) => {
		if (!part.startsWith(':')) return part;

		const paramKey = part.slice(1, part.includes('(') ? part.indexOf('(') : undefined);

		if (!params || !params[paramKey]) throw new Error(`Missing param '${paramKey}' for path '${path}'`);
		return params[paramKey];
	});

	return replaced.join('/');
}
