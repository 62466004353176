import React, { useState } from 'react';
import * as firebase from 'firebase/app';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import getPlainRoutes from './routes';
import { config } from './firebase/firebaseConfigs';
import { HelmetProvider } from 'react-helmet-async';
import { FirestoreProvider } from '@react-firebase/firestore';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import ContactUsModal from './Components/ContactUsModal/ContactUsModal';

import { ReactComponent as ContactUs } from './Components/Icons/contactUs.svg';
import { ReactComponent as Close } from './Components/Icons/close.svg';

import './App.scss';
import { useSessionStorage } from './Components/hooks/useSessionStorage';

export default function App() {
	const [covidOpened, setCovidOpened] = useState(false);
	const [extraStaffButton, setExtraStaffButton] = useState(false);
	const [closedExtraStaff, setClosedExtraStaff] = useState(false);
	const [contactModal, setContactModal] = useState(false);

	const [language, setLanguage] = useSessionStorage('language', 'en');

	setTimeout(() => setExtraStaffButton(true), 30000);
	clearTimeout(setTimeout(() => setExtraStaffButton(true), 30000));

	return (
		<FirestoreProvider firebase={firebase} {...config}>
			<HelmetProvider>
				<Router>
					<div className="componentWrapper">
						<Header setCovidOpened={setCovidOpened} language={language} setLanguage={setLanguage} covidOpened={covidOpened} />
						<Switch>
							{getPlainRoutes().map(({ path, component: Component, exact }) => (
								<Route path={path} render={(props) => <Component {...props} />} exact={exact} key={path || '/404'} />
							))}
						</Switch>
						<Footer />
						{!covidOpened && extraStaffButton && !closedExtraStaff && (
							<button onClick={() => setContactModal(true)} className="extraStaffContactButton">
								<span className="extraStaffContactText">Send us message</span>
								<ContactUs />
								<button
									onClick={(e) => {
										e.stopPropagation();
										setClosedExtraStaff(true);
									}}
									className="closeExtraStaffContact"
								>
									<Close />
								</button>
							</button>
						)}
						{setContactModal && <ContactUsModal contactModal={contactModal} setContactModal={setContactModal} />}
					</div>
				</Router>
			</HelmetProvider>
		</FirestoreProvider>
	);
}
